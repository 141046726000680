<template>
  <v-card>
    <v-card-title>
      Seguimiento de Montos del Proyecto
    </v-card-title>
    <v-card-text>
      <v-data-table
          dense
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          align="center"
          class="elevation-1">
        <template v-slot:item.amountRequested="{ item }">
          <v-chip
              color="green"
              dark>
            <b>${{ Number(item.amountRequested).toLocaleString() }}</b>
          </v-chip>
        </template>
        <template v-slot:item.percentageAmountRequestedProject="{ item }">
          <v-chip
              color="green"
              dark
          >
            <b>{{ item.percentageAmountRequestedProject }}%</b>
          </v-chip>
        </template>
        <template v-slot:item.percentageAmountRequestedCostCenter="{ item }">
          <v-chip
              color="green"
              dark
          >
            <b>{{ item.percentageAmountRequestedCostCenter }}%</b>
          </v-chip>
        </template>
        <template v-slot:item.amountRequestedDate="{ item }">
          <v-chip
              color="green"
              dark>
            <b>{{ item.amountRequestedDate }}</b>
          </v-chip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Amounts",
  data(){
    return{
      rows: [this.project],
      headers: [
        {
          text: 'Monto Solicitado',
          align: 'start',
          sortable: true,
          value: 'amountRequested',
        },
        { text: 'Monto Porcentaje Proyecto', value: 'percentageAmountRequestedProject' },
        { text: 'Monto Porcentaje Unidad', value: 'percentageAmountRequestedCostCenter' },
        { text: 'Última Actualizacion', value: 'amountRequestedDate' },
      ],
    }
  },
  props:{
    project: {
      type: Object,
    }
  },
  methods:{
    getDateFormatter(timestamp){
      let date = new Date(timestamp);
      var t = date.toISOString().split(/[- : T ]/);
      return `${t[2]}-${t[1]}-${t[0]} ${t[3]}:${t[4]}`;
    }
  }
}
</script>

<style scoped>

</style>