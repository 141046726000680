var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Seguimiento de Montos del Proyecto ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":this.headers,"items":this.rows,"item-key":"id","align":"center"},scopedSlots:_vm._u([{key:"item.amountRequested",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"green","dark":""}},[_c('b',[_vm._v("$"+_vm._s(Number(item.amountRequested).toLocaleString()))])])]}},{key:"item.percentageAmountRequestedProject",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"green","dark":""}},[_c('b',[_vm._v(_vm._s(item.percentageAmountRequestedProject)+"%")])])]}},{key:"item.percentageAmountRequestedCostCenter",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"green","dark":""}},[_c('b',[_vm._v(_vm._s(item.percentageAmountRequestedCostCenter)+"%")])])]}},{key:"item.amountRequestedDate",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"green","dark":""}},[_c('b',[_vm._v(_vm._s(item.amountRequestedDate))])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }