<template>
    <v-container fluid class="content">
          <h1><strong>Porcentaje de Avance de Hitos: {{percentAvg}}%</strong></h1>
      <v-divider class="mt-2 mb-2"></v-divider>
      <v-card elevation="0">
        <v-card-title>Seguimiento de Hitos del Proyecto</v-card-title>
        <v-card-text>
          <v-row class="ma-0 pa-0">
            <v-col cols="2">
              <strong>Hito</strong>
            </v-col>
            <v-col cols="3">
              <strong>Actividades</strong>
            </v-col>
            <v-col cols="2">
              <strong>Porcentaje de Avance</strong>
            </v-col>
            <v-col cols="2">
              <strong>Observaciones</strong>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row align="center" v-if="milestones.length === 0" class="ma-0 pa-0">
            <v-col cols="2">
              Sin Hitos
            </v-col>
          </v-row>
            <v-row align="center" v-for="(item, itemIndex) in milestones" :key="itemIndex" class="ma-0 pa-0">
            <v-col cols="2">
              <v-textarea
                  filled
                  name="input-7-3"
                  label="Nombre"
                  color="primary-color"
                  no-resize
                  v-model="milestones[itemIndex].nombre"
                  readonly
              ></v-textarea>
            </v-col>
            <v-col cols="3">
              <v-textarea
                  filled
                  name="input-7-4"
                  label="Actividades"
                  color="primary-color"
                  no-resize
                  v-model="milestones[itemIndex].actividad"
                  readonly
              ></v-textarea>
            </v-col>
            <v-col cols="2">
              <v-tooltip top max-width="300px">
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                  <v-select
                      class="mt-3 mb-4"
                      placeholder="Selecciona Porcentaje de Avance"
                      :items="percents"
                      item-text="text"
                      item-value="value"
                      icon="mdi-arrow-bottom-right-thin-circle-outline"
                      :disabled="milestones[itemIndex].disabled"
                      v-model="milestones[itemIndex].porcentaje"
                      @change="updatePercent(itemIndex)"
                      :loading="milestones[itemIndex].disabled"
                  />
                  </div>
                </template>
                <span>Seleccione el porcentaje de avance que el presente hito ha desarrollado y registre sus observaciones. En caso de ser pertinente, anule/elimine el hito. </span>
              </v-tooltip>
            </v-col>
            <v-col cols="5">
              <v-row>
                <v-col cols="10">
                  <v-textarea
                      filled
                      label="Observaciones"
                      color="primary-color"
                      no-resize
                      name="input-7-5"
                      v-model="milestones[itemIndex].observaciones"
                      :readonly="!milestones[itemIndex].editObservations"
                      :loading="milestones[itemIndex].loading"
                      counter="250"
                      :rules="[v => (v || '' ).length <= 250 || 'Máximo soportado 250 caracteres.']"
                  ></v-textarea>
                </v-col>
                <v-col cols="2">
                  <div class="text-left">
                    <div class="my-1">
                      <v-btn
                          color="#EA7730"
                          dark
                          v-if="!milestones[itemIndex].editObservations"
                          @click="()=>{milestones[itemIndex].editObservations = true; milestones[itemIndex].obsInicial =  milestones[itemIndex].observaciones}">
                        <small>Editar</small>
                      </v-btn>
                      <v-btn
                          color="#EA7730"
                          dark
                          v-if="milestones[itemIndex].editObservations"
                          @click="()=>{milestones[itemIndex].editObservations = false; milestones[itemIndex].observaciones =  milestones[itemIndex].obsInicial }">
                        <small>Cancelar</small>
                      </v-btn>
                    </div>
                    <div class="my-1">
                      <v-btn
                          color="#EA7730"
                          dark
                          v-if="milestones[itemIndex].editObservations"
                          @click="updateObservation(itemIndex)"
                          :disabled="milestones[itemIndex].loading">
                        <small>Guardar</small>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
import {mapActions} from "vuex";
export default {
  props: {
    project: {
      type: Object,
    },
    loading:{
      type: Boolean,
    }
  },
  components: {
  },
  data() {
    return {
      milestones: [],
      percents: [
        {value: 0, text: '0%'},
        {value: 10, text: '10%'},
        {value: 20, text: '20%'},
        {value: 30, text: '30%'},
        {value: 40, text: '40%'},
        {value: 50, text: '50%'},
        {value: 60, text: '60%'},
        {value: 70, text: '70%'},
        {value: 80, text: '80%'},
        {value: 90, text: '90%'},
        {value: 100, text: '100%'},
        {value: null, text: 'Anular Hito'},
      ],
      percentAvg: 0
    }
  },
  methods:{
    ...mapActions('main', [
      'updatePorcentajeAvanceHito',
      'updateObservacionesHito',
      'getHitoByIdProyecto',
    ]),
    updatePercent(itemIndex){
      this.milestones[itemIndex].disabled = true;
      this.updatePorcentajeAvanceHito(
          {'id': this.milestones[itemIndex].id,
            'percent': this.milestones[itemIndex].porcentaje})
          // eslint-disable-next-line no-unused-vars
          .then(response=>{
            this.milestones[itemIndex].disabled = false;
            this.calculateAverage();
          }).catch(err => {console.log(err); this.milestones[itemIndex].disabled = false;});
    },
    updateObservation(itemIndex){
      this.milestones[itemIndex].loading = true;
      this.updateObservacionesHito(
          {
            'id': this.milestones[itemIndex].id,
            'observation': this.milestones[itemIndex].observaciones})
          // eslint-disable-next-line no-unused-vars
          .then(response=>{
            this.milestones[itemIndex].loading = false;
            this.milestones[itemIndex].editObservations = false;
          }).catch(err => {
        console.log(err);
        this.milestones[itemIndex].loading = false;
        this.milestones[itemIndex].editObservations = false;});
    },
    calculateAverage() {
      let total = 0, length = this.milestones.length;
      let elements = this.milestones.length;
      for (var i = 0; i < length; i++) {
        if(this.milestones[i].porcentaje != null)
          total += parseFloat(this.milestones[i].porcentaje);
        else
          elements -=1
      }
      if(total === 0 && elements === 0)
        this.percentAvg = 0;
      else
        this.percentAvg = Math.floor(total / elements);
    },
    async getProjectMilestones(){
        await this.getHitoByIdProyecto(this.project.id).then(response => {
          response.data.response.forEach(milestone => {
              this.milestones.push({
              id: milestone.id,
              nombre: milestone.nombreHito,
              actividad: milestone.actividades,
              porcentaje: milestone.porcentajeAvanceHito,
              observaciones: milestone.observacionesHito,
              obsInicial: "",
              ready: true,
              disabled: false,
              editObservations: false,
              loading: false
            });
          });
        }).catch(err =>{ console.log(err);});
    }
  },
  async created() {
    this.$emit("set-loading", true);
    await this.getProjectMilestones();
    this.calculateAverage();
    this.$emit("set-loading", false);
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return (
          this.$vuetify.breakpoint.md ||
          this.$vuetify.breakpoint.lg ||
          this.$vuetify.breakpoint.xl
      );
    },
  },
}
</script>
