<template>
  <v-row>
    <v-col align-self="center" class="ma-0 pa-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-card elevation="0" class="w-full pa-4 mt-2">
        <v-card-title><b>Finalización de Proyectos</b></v-card-title>
        <v-card-text>

          <div align="center" v-if="this.project.evaluation === null">
            <v-btn
                :loading="loading"
                style="background-color: #EA7730; color: white"
                text
                @click="() => initializeEvaluation()">
              Iniciar Evaluacion de Finalización
            </v-btn>
          </div>

          <div align="center" v-else>
            <div v-if="!finalize">
              <v-toolbar flat >
                <v-toolbar-title>Encuesta de Finalización</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" style="background-color: #EA7730;
                  color: white" dark class="mr-md-1">Finalizar Proyecto</v-btn>
                  </template>
                  <v-card>
                <v-card-title>
                  <div class="text-center">
                    <h3><strong>Evaluación</strong></h3>
                  </div>
                </v-card-title>
                <v-card-text>
                  <v-row class="mb-2 mt-1">
                    <v-col cols="6">
                      <h2>{{this.project.evaluation.name}}</h2>
                    </v-col>
                    <v-col cols="6">
                      <h2>Respuestas</h2>
                    </v-col>
                  </v-row>
                  <v-row v-for="(item, itemIndex) in answers" :key="itemIndex">
                    <v-col cols="5">
                      <h3>{{answers[itemIndex].questions.name}}</h3>
                    </v-col>
                    <v-col cols="5">
                      <v-textarea
                          v-model="answers[itemIndex].value"
                          filled
                          :counter="1500"
                          :rules="[rules.length(1500)]"
                          rows="5"
                          label="Comentario"
                          color="primary-color"
                          no-resize
                          name="input-8-5"
                          :readonly="!answers[itemIndex].edit"
                          :loading="answers[itemIndex].loading"
                      ></v-textarea>
                    </v-col>
                    <v-col cols="2">
                      <div class="text-left">
                        <div class="my-1">
                          <v-btn
                              color="#EA7730"
                              dark
                              v-if="!answers[itemIndex].edit"
                              @click="()=>{answers[itemIndex].edit = true ; answers[itemIndex].lastValue = answers[itemIndex].value}">
                            <small>Editar</small>
                          </v-btn>
                          <v-btn
                              color="#EA7730"
                              dark
                              v-if="answers[itemIndex].edit"
                              @click="()=>{answers[itemIndex].edit = false; answers[itemIndex].value = answers[itemIndex].lastValue}">
                            <small>Cancelar</small>
                          </v-btn>
                        </div>
                        <div class="my-1">
                          <v-btn
                              color="#EA7730"
                              dark
                              v-if="answers[itemIndex].edit"
                              @click="()=>{update(itemIndex);}"
                              :disabled="answers[itemIndex].loading">
                            <small>Guardar</small>
                          </v-btn>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-card>
                    <v-card-title>Subir archivo opcional de evidencia</v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col class="col-10">
                          <v-file-input
                              v-model="file"
                              chips
                              counter
                              show-size
                              accept=".pdf"
                              truncate-length="50"
                              label="Archivo opcional como evidencia de desarrollo del proyecto"
                          ></v-file-input>
                          <div v-if="this.filename.length > 0">
                            <h4><p><b>Ya se ha subido el archivo {{this.filename}} y fue subido con fecha {{project.finishFileUploadDate}}</b></p></h4>
                          </div>
                        </v-col>
                        <v-col class="col-2">
                          <v-btn
                              class="mt-3"
                              style="background-color: #EA7730; color: white"
                              text
                              @click="uploadOptionalFileFinishProject()">
                            Subir
                          </v-btn>

                          <v-btn v-if="this.filename.length > 0"
                              class="ml-5 mt-3"
                              style="background-color: #EA7730; color: white"
                              text
                              @click="showFileFinishProject()">
                              <v-icon center>mdi-eye</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-card-text>

                <v-card-actions class="mt-3">
                  <v-btn
                      style="background-color: #EA7730; color: white"
                      text
                      @click="close()">
                    Cerrar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <div v-if="!enableFinishButton" class="mr-2">Debe responder todas las preguntas para </div>
                  <v-btn
                      :disabled="!enableFinishButton"
                      style="background-color: #EA7730; color: white"
                      text
                      @click="setFinishProject()">
                    Finalizar evaluacion final de proyecto
                  </v-btn>
                </v-card-actions>
              </v-card>
                </v-dialog>
              </v-toolbar>
              <h2>Proyecto no ha sido finalizado</h2>
            </div>
            <div v-else>
              <v-card>
                <v-card-title>Proyecto finalizado con fecha {{this.project.finishDate}}</v-card-title>
                <v-card-text>
                  <v-data-table
                      dense
                      :headers="this.headers"
                      :items="this.answers"
                      item-key="id"
                      class="elevation-1">
                  </v-data-table>
                  <v-card>
                    <v-card-title>Archivo Evidencia</v-card-title>
                    <v-card-text>
                      <div class="text-left" v-if="this.filename.length > 0">
                      <p>{{this.filename}}</p>
                        <v-btn
                          class="ml-5 mt-3"
                          style="background-color: #EA7730; color: white"
                          text
                          @click="showFileFinishProject()">
                        <v-icon center>mdi-eye</v-icon>
                      </v-btn>
                      </div>
                      <div v-else>
                        <h4><p><b>Sin archivo de evidencia</b></p></h4>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions} from 'vuex'
import Swal from "sweetalert2";
export default {
  name: "ProjectFinisher",
  data(){
    return {
      loading: false,
      dialog: false,
      enableFinishButton: false,
      finalize: false,
      answers: [],
      filename: "",
      file: {},
      headers: [
        {
          text: 'Pregunta',
          align: 'start',
          sortable: true,
          value: 'questions.name',
        },
        { text: 'Respuesta', value: 'value' },
      ],
      rules: {
        length: len => v => (v || '').length > len && `Excede el largo de caracteres ${len}`
      },
    }
  },
  props: {
    project: {
      type: Object,
    }
  },
  methods: {
    ...mapActions('main', [
      'getEvaluationForPeriod',
      'createQuestionAnswers',
      'getAllQuestionsAnswerByProject',
      'updateAnswer',
      'uploadFileFinishProject',
      'downloadFileFinishProject',
      'finishProject'
    ]),
    close(){
      this.dialog = false;
    },
    async initializeEvaluation(){
      await this.createItemsQuestionAnswers();
      this.setEvaluationId();
    },
    update(itemIndex) {
      this.updateAnswer(this.answers[itemIndex]).then(response => {
        this.getAllItemsQuestionsAnswer();
        Swal.fire({
          title: 'Modificar respuesta de encuesta',
          text: 'Se ha modificado la respuesta de encuesta en pregunta ' + response.data.response,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        });
        this.loading = false;
      }).catch(e => {
        console.log(e.response.data);
        Swal.fire({
          title: 'Modificar respuesta de encuesta',
          text: e.response.data.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    createItemsQuestionAnswers() {
      var data = {
        "evaluations": this.evaluations,
        "project": this.project.id
      }
      this.createQuestionAnswers(data).then(response => {
        this.getAllItemsQuestionsAnswer();
        this.project.evaluation = {id: response.data.response};
        Swal.fire({
          title: 'Iniciar Encuesta',
          text: 'Se ha Iniciado la Encuesta para finalizar Proyecto',
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        })
        this.close();
      }).catch(e => {
        console.log(e.response.data);
        Swal.fire({
          title: 'Iniciar Encuesta',
          text: e.response.data.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    getAllItemsQuestionsAnswer(){
      this.answers = [];
      var withResponse = 0;
      this.getAllQuestionsAnswerByProject(this.project.id).then(response => {
        response.data.response.forEach((item) => {
            item.edit = false;
            item.loading = false;
            item.lastValue = "";
            this.answers.push(item);
            if(item.value.length > 0)
              withResponse++;
          });
          if(withResponse === response.data.response.length && withResponse > 0){
            this.enableFinishButton = true;
          }else{
            this.enableFinishButton = false;
          }
        }).catch(e => {
        console.log(e);
      });
    },
    setFinishProject(){
      this.finishProject(this.project.id).then(response => {
        Swal.fire({
          title: 'Finalizar proyecto',
          text: 'Se ha finalizado su proyecto con id: ' + response.data.response,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        });
        this.close();
        this.finalize = true;
      }).catch(e => {
        console.log(e.response.data);
        Swal.fire({
          title: 'Finalizar proyecto',
          text: e.response.data.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'error',
        });
        this.loading = false;
      });
    },
    setEvaluationId(){
      if(this.project.evaluation === null){
        this.evaluations = null;
      }else{
        this.evaluations = this.project.evaluation.id;
      }
    },
    showFileFinishProject(){
      this.downloadFileFinishProject(this.project.id).then(response => {
        var a = document.createElement("a");
        a.href = "data:" + response.data.response.mime + ";base64," + response.data.response.file;
        a.download = response.data.response.filename;
        a.click();
        a.remove();
      }).catch(e => {
        console.log(e.response.data);
        Swal.fire({
          title: 'Archivo evidencia',
          text: e.response.data.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'error',
        });
        this.loading = false;
      })
    },
    uploadOptionalFileFinishProject(){
      var data = {
        "file": this.file,
        "id": this.project.id
      }
      this.uploadFileFinishProject(data).then(response => {
        console.log(response.data.response);
        Swal.fire({
          title: 'Archivo evidencia',
          text: 'Se ha subido el archivo',
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'success',
        });
        this.loading = false;
        this.filename = this.file.name;
      }).catch(e => {
        console.log(e.response.data);
        Swal.fire({
          title: 'Archivo evidencia',
          text: e.response.data.message,
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'error',
        });
        this.loading = false;
      });
    }
  },
  async created() {
    this.finalize = this.project.finishStatus;
    this.filename = this.project.finishFileName===null?"":this.project.finishFileName;
    this.setEvaluationId()
    await this.getAllItemsQuestionsAnswer();
  }
}
</script>

<style scoped>

</style>