<template>
  <div class="pt-15 my-10 main-component-content">
    <StepperComponent/>
    <v-row class="ma-0 pa-0">
      <v-col cols="12">
          <v-col class="mb-8 mt-0 mx-0 pa-0" align-self="start">
            <h1 class="text-left w-full mb-2">Seguimiento y Evaluación</h1>
            <p class="text-left w-full">
              A continuación, podrá realizar el seguimiento semestral del Proyecto Operativo a través del avance del presupuesto solicitado y de los hitos desarrollados, así como también una evaluación de finalización (cierre) anual por medio de las apreciaciones generales de su ejecución.
            </p>
            <hr>
            <hr>
            <v-row class="ma-0 mb-8 pa-0">
              <v-col align-self="center" class="ma-0 pa-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-card elevation="0" class="w-full pa-4 mt-2">
                  <v-card-title><b>Seguimiento de Proyectos</b></v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <select-component
                    class="mt-3 mb-4"
                    placeholder="Selecciona un Proyecto"
                    :items="projects"
                    itemtext="nombre"
                    icon="mdi-arrow-bottom-right-thin-circle-outline"
                    @value="(value) => { setProjectStage(value) }"/>
                    <v-progress-linear v-if="loading" color="#EA7730" indeterminate rounded height="3"/>
                    <TracingShowMoneyAmount :project="project" v-if="project !== null" :key="projectid.toString().concat('a')"/>
                    <TracingSetterAndShowMilestone :loading="loading" @set-loading="setLoading" :project="project" v-if="project !== null" class="mb-6" :key="projectid.toString().concat('b')"/>
                    <ProjectFinisherComponent :project="project" v-if="project !== null" :key="projectid.toString().concat('c')"/>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-col>
        <div class="welcome-link-group mb-8">
          <v-btn style="background-color: #EA7730; color: white"
                  dark class="mr-md-1" @click="$router.push('home')">Volver</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapActions, mapGetters,} from "vuex";
import Swal from "sweetalert2";
import StepperComponent from '../../components/steps/Stepper';
import SelectComponent from "../../components/form/Select";
import TracingShowMoneyAmount from "../../components/Tracing/TracingShowMoneyAmount";
import TracingSetterAndShowMilestone from '../../components/Tracing/TracingSetterAndShowMilestone';
import ProjectFinisher from "@/components/Project/ProjectFinisher";
export default {
  name: 'application-form',
  components: {
    'StepperComponent': StepperComponent,
    'SelectComponent': SelectComponent,
    'TracingSetterAndShowMilestone': TracingSetterAndShowMilestone,
    'TracingShowMoneyAmount': TracingShowMoneyAmount,
    'ProjectFinisherComponent': ProjectFinisher
  },
  data: () => ({
    projects: [],
    project: null,
    projectid: 0,
    loading: false
  }),
  methods: {
    ...mapActions('main', [
      'getAllProyecto',
    ]),
    setLoading(state){
      this.loading = state;
    },
    getProjects(){
      this.getAllProyecto(this.info.ccValor)
          .then(response => {
            this.projects = response.data.response;
          })
          .catch(err => console.log(err));
    },
    setProjectStage(project){
      if(project.aprobadoDGE){
        this.project = project;
        this.projectid = project.id
      }else{
        Swal.fire({
          title: 'Proyecto No Aprobado por DGE',
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
      }
    }
  },
  created() {
    this.getProjects();
  },
  computed: {
    ...mapGetters({
      info: ['authentication/info'],
      fullTokenDecoded: ['authentication/fullTokenDecoded']
    })
  }
}
</script>
<style>
.main-component-content {
  min-height: 100vh;
  width: 100%;
}
</style>